import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import { routerMessages } from '@/router/utils/router'
import { roleRoutes } from '@/tests/role'
import { getUserInfo } from '@/api/userInfo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home')
      }
    ]
  },
  {
    path: '/navigation',
    component: () => import('@/views/navigation')
  },
  {
    path: '/empty',
    name: 'Empty',
    component: () => import('@/views/empty')
  },
  {
    path: '/project',
    component: Layout,
    redirect: '/project/project-list',
    children: [
      {
        path: 'project-list',
        name: 'ProjectList',
        component: () => import('@/views/project/project-list240219.vue')
      },
      {
        path: 'project-timeline',
        name: 'ProjectTimeline',
        component: () => import('@/views/project/project-timeline.vue')
      },
      {
        path: 'project-worktask',
        name: 'ProjectWorktask',
        component: () => import('@/views/project/project-worktask.vue')
      },
      {
        path: 'project-node',
        name: 'ProjectNode',
        component: () => import('@/views/project/project-node.vue')
      },
      {
        path: 'project-node-detail',
        name: 'ProjectNodeDetail',
        component: () => import('@/views/project/project-node-detail.vue')
      },
      {
        path: 'project-team',
        name: 'ProjectTeam',
        component: () => import('@/views/project/project-team.vue')
      },
      {
        path: 'project-team-manage',
        name: 'ProjectTeamManage',
        component: () => import('@/views/project/project-team-manage.vue')
      },
      {
        path: 'project-issues-fill',
        name: 'ProjectIssuesFill',
        component: () => import('@/views/project/project-issues-fill231215.vue')
      },
      {
        path: 'project-issues-list',
        name: 'ProjectIssuesList',
        component: () => import('@/views/project/project-issues-list231215.vue')
      },
      {
        path: 'project-issues',
        name: 'ProjectIssues',
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: 'project-issues-export',
        name: 'ProjectIssuesExport',
        component: () => import('@/views/project/project-issues-export231215.vue')
      },
      {
        path: 'project-design',
        name: 'ProjectDesign',
        component: () => import('@/views/project/project-design.vue')
      },
      {
        path: 'project-issues-analysis',
        name: 'ProjectIssuesAnalysis',
        component: () => import('@/views/project/project-issues-analysis.vue')
      }
    ]
  },
  {
    path: '/customer',
    component: Layout,
    redirect: '/customer/customer-management',
    children: [
      {
        path: 'customer-management',
        name: 'CustomerManagement',
        component: () => import('@/views/customer/customer-management.vue')
      },
      {
        path: 'customer-feedback',
        name: 'CustomerFeedback',
        component: () => import('@/views/customer/customer-feedback.vue')
      }
    ]
  },
  {
    path: '/suggestion',
    component: Layout,
    redirect: '/suggestion/suggestion-submit',
    children: [
      {
        path: 'suggestion-submit',
        name: 'SuggestionSubmit',
        component: () => import('@/views/suggestion/suggestion-submit.vue')
      },
      {
        path: 'suggestion-list',
        name: 'SuggestionList',
        component: () => import('@/views/suggestion/suggestion-list.vue')
      }
    ]
  },
  {
    path: '/manhour',
    component: Layout,
    redirect: '/manhour/manhour-list',
    children: [
      {
        path: 'manhour-record',
        name: 'ManhourRecord',
        component: () => import('@/views/manhour/manhour-record230926.vue')
      },
      {
        path: 'manhour-condition',
        name: 'ManhourCondition',
        component: () => import('@/views/manhour/manhour-condition.vue')
      },
      {
        path: 'manhour-list',
        name: 'ManhourList',
        component: () => import('@/views/manhour/manhour-list230926.vue')
      },
      {
        path: 'manhour-analysis',
        name: 'ManhourAnalysis',
        component: () => import('@/views/manhour/manhour-analysis.vue')
      },
      {
        path: 'manhour-fill-situation',
        name: 'ManhourFillSituation',
        component: () => import('@/views/manhour/manhour-fill-situation.vue')
      },
      {
        path: 'manhour-approve',
        name: 'ManhourApprove',
        component: () => import('@/views/manhour/manhour-approve.vue')
      },
      {
        path: 'manhour-export',
        name: 'ManhourExport',
        component: () => import('@/views/manhour/manhour-export230908.vue')
      }
    ]
  },
  {
    path: '/office',
    component: Layout,
    redirect: '/office/manhour-business-trips',
    children: [
      {
        path: 'manhour-business-trips',
        name: 'ManhourBusinessTrips',
        component: () => import('@/views/manhour/manhour-business-trips.vue')
      },
      {
        path: 'office-absence',
        name: 'OfficeAbsence',
        component: () => import('@/views/office/office-absence.vue')
      }
    ]
  },
  {
    path: '/other',
    component: Layout,
    redirect: '/other/other-frequently-web',
    children: [
      {
        path: 'other-frequently-web',
        name: 'OtherFrequentlyWeb',
        component: () => import('@/views/other/other-frequently-web.vue')
      },
      {
        // https://miszs.zs-tek.com/#/other/other-meeting-check?corpld=$CORPID$
        path: 'other-meeting-check',
        name: 'OtherMeetingCheck',
        component: () => import('@/views/other/other-meeting-check.vue')
      }
    ]
  },
  {
    path: '/managementCenter',
    component: Layout,
    redirect: '/managementCenter/other-questionnaire',
    children: [
      {
        path: 'other-questionnaire',
        name: 'OtherQuestionnaire',
        component: () => import('@/views/other/other-questionnaire.vue')
      },
      {
        path: 'developer-questionnaire-configure',
        name: 'DeveloperQuestionnaireConfigure',
        component: () => import('@/views/developer/developer-questionnaire-configure.vue')
      },
      {
        path: 'developer-questionnaire-list',
        name: 'DeveloperQuestionnaireList',
        component: () => import('@/views/developer/developer-questionnaire-list240717.vue')
      }
    ]
  },
  {
    path: '/performance',
    component: Layout,
    redirect: '/performance/performance-questionnaire',
    children: [
      {
        path: 'performance-questionnaire',
        name: 'PerformanceQuestionnaire',
        component: () => import('@/views/performance/performance-questionnaire.vue')
      },
      {
        path: 'performance-authority',
        name: 'PerformanceAuthority',
        component: () => import('@/views/performance/performance-authority.vue')
      },
      {
        path: 'performance-analysis',
        name: 'PerformanceAnalysis',
        component: () => import('@/views/performance/performance-analysis.vue')
      }
    ]
  },
  {
    path: '/developer',
    component: Layout,
    redirect: '/developer/developer-manhour-batchadd',
    children: [
      {
        path: 'developer-manhour-batchadd',
        name: 'DeveloperManhourBatchadd',
        component: () => import('@/views/developer/developer-manhour-batchadd.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // alert(to.path)
  const zstekMisDingtalkEntrance = sessionStorage.getItem('zstek-mis-dingtalk-entrance') || null
  if (!zstekMisDingtalkEntrance) {
    sessionStorage.setItem('zstek-mis-dingtalk-entrance', to.path)
    // alert('已经存入' + to.path)
  }

  const token = JSON.parse(localStorage.getItem('zstek-mis-web-token'))
  if (token) {
    router.app.$options.store.commit('routerMessagesChange', routerMessages(roleRoutes, to.path))
    getUserInfo({ userId: token.uid })
      .then((res) => {
        router.app.$options.store.commit('userInfoChange', res.data.content)
        next()
      })
      .catch((err) => {
        console.log(err)
      })
  } else {
    if (to.path === '/project/project-issues-export') {
      next()
    } else if (to.path !== '/login') {
      next({ path: '/login' })
    } else {
      next()
    }
  }
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
