const roleRoutes = [
  {
    path: '/navigation',
    name: '导航页'
  },
  {
    path: '/home',
    name: '首页'
  },
  {
    path: '/project',
    name: '项目',
    children: [
      {
        path: '/project-list',
        name: '项目列表'
      },
      {
        path: '/project-timeline',
        name: '项目时程'
      },
      {
        path: '/project-worktask',
        name: '工作任务'
      },
      {
        path: '/project-design',
        name: '设计变更'
      },
      {
        path: '/project-node',
        name: '节点时间轴'
      },
      {
        path: '/project-node-detail',
        name: '节点详情'
      },
      {
        path: '/project-team',
        name: '加入组'
      },
      {
        path: '/project-team-manage',
        name: '管理组成员'
      },
      {
        path: '/project-issues-fill',
        name: '记录问题'
      },
      {
        path: '/project-issues-list',
        name: '问题列表'
      },
      {
        path: '/project-issues-analysis',
        name: '问题分析'
      },
      {
        path: '/project-issues-export',
        name: '问题图表'
      }
    ]
  },
  {
    path: '/customer',
    name: '客户',
    children: [
      {
        path: '/customer-management',
        name: '客户管理'
      },
      {
        path: '/customer-feedback',
        name: '问题反馈'
      }
    ]
  },
  {
    path: '/suggestion',
    name: '建议',
    children: [
      {
        path: '/suggestion-submit',
        name: '提交建议'
      },
      {
        path: '/suggestion-list',
        name: '建议列表'
      }
    ]
  },
  {
    path: '/manhour',
    name: '工时',
    children: [
      {
        path: '/manhour-record',
        name: '记录工时'
      },
      {
        path: '/manhour-condition',
        name: '工作情况'
      },
      {
        path: '/manhour-list',
        name: '工时列表'
      },
      {
        path: '/manhour-analysis',
        name: '工时分析'
      },
      {
        path: '/manhour-fill-situation',
        name: '填写情况'
      },
      {
        path: '/manhour-approve',
        name: '审核工时'
      },
      {
        path: '/manhour-export',
        name: '工时导出'
      },
      {
        path: '/manhour-business-trips',
        name: '出差情况'
      }
    ]
  },
  {
    path: '/office',
    name: '办公',
    children: [
      {
        path: '/manhour-business-trips',
        name: '出差情况'
      },
      {
        path: '/office-absence',
        name: '请假情况'
      }
    ]
  },
  {
    path: '/other',
    name: '其它',
    children: [
      {
        path: '/other-frequently-web',
        name: '工具网站'
      }
    ]
  },
  {
    path: '/performance',
    name: '绩效',
    children: [
      {
        path: '/performance-questionnaire',
        name: '绩效评价'
      },
      {
        path: '/performance-authority',
        name: '评价权限'
      },
      {
        path: '/performance-analysis',
        name: '评价列表'
      }
    ]
  },
  {
    path: '/developer',
    name: '开发者模式',
    children: [
      {
        path: '/developer-manhour-batchadd',
        name: '批量工时添加'
      }
    ]
  }
]

module.exports = {
  roleRoutes
}
